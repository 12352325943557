.Chrome {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
}

.Video {
  margin: 0 auto;
  width: 40vw;
  height: auto;
}

.Image {
  margin: 0 auto;
  width: 40vw;
  height: auto;
}

.Subtitle {
  margin-top: 5rem;
  font-weight: bold;
  font-size: 1rem;
}
